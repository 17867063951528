import React, { useRef, useState } from "react";
import Heading from "../components/Heading";
import { IoIosPaperPlane } from "../components/Icons";
import Button from "../components/Button";

const Contact = () => {
  const nameRef = useRef(null);
  const [name, setName] = useState("");

  const emailRef = useRef(null);
  const [email, setEmail] = useState("");

  const messageRef = useRef(null);
  const [message, setMessage] = useState("");

  const [buttonText, setButtonText] = useState("Send Email");

  const isEmailValid = email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // eslint-disable-next-line no-unused-vars
  const onSubmit = e => {
    let formValid = true;
    e.preventDefault();

    if (!name) {
      formValid = false;
      const nodeName = nameRef.current;
      nodeName.classList.add("border-red-500", "animated", "shake");
      setTimeout(() => {
        nodeName.classList.remove("border-red-500", "animated", "shake");
      }, 2000);
    }

    if (!email || !isEmailValid(email)) {
      formValid = false;
      const nodeName = emailRef.current;
      nodeName.classList.add("border-red-500", "animated", "shake");
      setTimeout(() => {
        nodeName.classList.remove("border-red-500", "animated", "shake");
      }, 2000);
    }

    if (!message) {
      formValid = false;
      const nodeName = messageRef.current;
      nodeName.classList.add("border-red-500", "animated", "shake");
      setTimeout(() => {
        nodeName.classList.remove("border-red-500", "animated", "shake");
      }, 2000);
    }

    if (formValid) {
      const url = "api/sendEmail.js"
      const opts = {
        method: "POST",
        body: JSON.stringify({ name, email, message }),
      };

      fetch(url, opts)
        .then(() => {
          setButtonText("Message Received!");
          setName("");
          setEmail("");
          setMessage("");
        })
        .catch(() => setButtonText("An Error Occurred!"));
    }
  };

  function sendMail() {
    var link = "mailto:hello@vamilgandhi.com"
    ;
    
    window.location.href = link;
}

return (
    <section id="contact">
      <Heading icon={IoIosPaperPlane} title="Contact" />

        <Button
          type="submit"
          className="mt-6"
          icon={IoIosPaperPlane}
          title={buttonText}
          onClick={sendMail}
        />
      
    </section>
  );
};

export default Contact;
