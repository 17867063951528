import React from "react";
import PropTypes from "prop-types";

const Heading = ({ title }) => {
  

  return (
    <div className="heading flex items-center pb-8">
      <h6 className="font-bold uppercase text-sm leading-none">{title}</h6>
    </div>
  );
};

Heading.propTypes = {

  title: PropTypes.string.isRequired,
};

export default Heading;
